import React, { Fragment } from "react"
import Img from "gatsby-image"
import { css } from "@emotion/react"

import ArrowLeft from "../modules/ArrowLeft"
import ArrowRight from "../modules/ArrowRight"
import ScrollDown from "../modules/ScrollDown"

import { slugify } from "../functions/slugify"

export default ({ current, prev, next, scroll }) => (
  <Fragment>
    {/* BACKGROUND */}

    {current.cover.fluid && (
      <div
        className="uk-position-fixed uk-width-1-2@m"
        style={{ zIndex: -1 }}
        css={css`
          @media (max-width: 960px) {
            height: 65vh;
          }
          @media (min-width: 960px) {
            height: 50vh;
          }
        `}
      >
        <Img
          className="uk-position-cover uk-position-absolute"
          fluid={current.cover.fluid}
        />
      </div>
    )}

    {/* END : BACKGROUND */}

    {/* OVERLAY */}

    <div
      className="uk-light"
      css={css`
        @media (max-width: 960px) {
          height: 65vh;
        }
        @media (min-width: 960px) {
          height: 50vh;
        }
      `}
    >
      <div className="uk-position-relative uk-light uk-height-1-1">
        {/* {current.cover.fluid && (
        <Img
          className="uk-position-absolute uk-position-cover"
          fluid={current.cover.fluid}
        />
      )} */}

        <div className="uk-overlay uk-overlay-primary uk-position-cover uk-flex uk-flex-column uk-flex-center uk-flex-middle">
          <div
            className="uk-text-center uk-animation-fade uk-margin-bottom"
            style={{ fontVariantCaps: "small-caps" }}
          >
            <h3 className="uk-margin-remove">{current.name}</h3>
            <h1 className="uk-padding uk-margin-remove">
              {current.title}
            </h1>
            <h3 className="uk-margin-remove">{current.subtitle}</h3>
          </div>
          {/* {current.thumb && (
            <a
              href="#video"
              className="uk-width-1-2 uk-width-1-3@s uk-width-1-2@m uk-width-1-3@l uk-width-1-4@xl uk-margin-top"
            >
              <Img
                fluid={{
                  ...current.thumb.fluid,
                  aspectRatio: 16 / 9,
                }}
                className="image-thumb uk-box-shadow-medium"
              />
            </a>
          )} */}
        </div>

        <div className="uk-position-bottom uk-position-small">
          {scroll && <ScrollDown to={"#" + slugify(scroll)} />}
        </div>

        {prev && (
          <div className="uk-position-center-left uk-position-small">
            <ArrowLeft url={prev} />
          </div>
        )}
        {next && (
          <div className="uk-position-center-right uk-position-small">
            <ArrowRight url={next} />
          </div>
        )}
      </div>
    </div>

    {/* END : OVERLAY */}
  </Fragment>
)
